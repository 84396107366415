import { AppRouteURLs } from '@Constants/app-route-urls';
import { LOCAL_STORAGE_DATA_KEYS } from '@Constants/localstorageDataModel';
import { getDataFromLocalStorage } from '@Src/utils/globalUtilities';
import React, { Component, useEffect, useLayoutEffect } from 'react'

import { Route, Navigate, useNavigate } from "react-router-dom";
import { JsxElement } from 'typescript';


interface IProps {
    // element: Component,
    // path: string,
    children: JSX.Element
}

const AuthGuardRoute = (props: IProps): JSX.Element => {

    const navigate = useNavigate();

    let key = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);

    useEffect(() => {
        if (!key) {
            navigate(AppRouteURLs.LANDING_PAGE);
        }
    }, [key])

    return (key) ? props.children : <Navigate to={AppRouteURLs.LANDING_PAGE} />
}

export default AuthGuardRoute;