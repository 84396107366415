import React, { useEffect } from 'react';
import BlackBoardImage from "../../assets/images/blackboard2.png"
import ValuesImage from "../../assets/images/values.jpg"
import { LOCAL_STORAGE_DATA_KEYS } from '@Constants/localstorageDataModel';
import { IToken } from '@Src/types/api-response-interface';
import { getDataFromLocalStorage } from '@Src/utils/globalUtilities';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import LifeCareerPdf from '@Pages/life-career-pdf/LifeCareerPdf';
const Assessment = () => {

    const token = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);
    const navigate = useNavigate()
    useEffect(() => {
        if (token) {
            const decodeToken: IToken = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000);
            if (decodeToken.exp < currentTime) {
                navigate('/')
                window.localStorage.clear()
            } else {
                navigate('/test')
            }
        }
    }, [token])
    return (
        <div className="md:tw-px-[10rem] md:tw-py-4 tw-p-4">
            {/* <h2><strong>Home</strong></h2> */}

            <div className="tw-flex tw-flex-wrap">
                <div className="tw-w-full md:tw-w-11/12">
                    <img
                        className="md:tw-float-right md:tw-w-1/2 md:tw-max-w-xs tw-p-2"
                        src={`${BlackBoardImage}`}
                        alt="Device"
                    />
                    <h4 className='tw-text-[#37B048] tw-text-xl'>What is most important to you? Your values!</h4>

                    <p className='tw-mt-4'>Values provide motivation and meaning in life. Values underlie every decision that you make.</p>

                    <p className='tw-mt-4'>
                        There is no choice or decision, no matter how large or small, which cannot be traced back to your values.
                        Values are your beliefs, goals, satisfactions, objectives, aspirations, guides... anything that leads to
                        meaning.
                    </p>
                    <p className='tw-mt-4'>
                        In career planning, understanding your values becomes all the more important because of all the decisions
                        that need to be made. For example, if you value having a lot of free time but choose a career that requires
                        60 hours a week on the job, you will feel a conflict between work and free time. When you find that your work
                        today does not match your values, you can begin to plan for a new career that will be more in line with what
                        is most important to you.
                    </p>

                    <h4 className='tw-text-[#37B048] tw-text-xl tw-my-5'>What is LifeCareerValues.com?</h4>
                    <img
                        className="tw-float-left md:tw-w-1/2 tw-w-full md:tw-max-w-xs tw-pr-5"
                        src={`${ValuesImage}`}
                        alt="Device"
                    />
                    <p className='tw-mt-4'>
                        This online assessment can help you understand your values so that you can make more informed life and
                        career-related decisions. The assessment is like a card sort, in that you are given statements (values) to
                        "sort" into different "piles" depending on how important it is to you. It consists of determining your
                        values in three parts:
                    </p>

                    <p className='tw-mt-4'>1 - Personal/Whole Life Values,</p>
                    <p className='tw-mt-4'>2 - Current/Most Recent Career Values,</p>
                    <p className='tw-mt-4'>3 - and Ideal Career Values.</p>

                    <p className='tw-mt-4'>
                        Comparing your responses to all three can help you see whether or not there is a conflict in your life and
                        career that may cause you to feel a disconnect. You can then use this information to help you make changes
                        that can lead to a more satisfying career and life. You are encouraged to use this assessment while working
                        with a career counselor/coach in order to maximize the results. However, you can still benefit from this
                        experience on your own. Just remember, this is not a test -- there are no right or wrong answers.
                    </p>

                    <h4 className='tw-text-[#37B048] tw-text-xl tw-my-5'>How can I take the assessment?</h4>
                    <ul className="tw-list-disc tw-list-inside">
                        <li>
                            First, you are off to a good start by reading this whole page! You may also wish to read the{' '}
                            <a href="page-faq.cfm">FAQs</a>
                        </li>

                        <li>Second, Purchase or Login:</li>
                        <ul className="tw-list-disc tw-list-inside tw-ml-5">
                            <li>
                                If you don't have a career counselor/coach but would like to purchase and take the assessment,{' '}
                                <a href="https://www.lifecareervalues.com/register/register.cfm">click here</a> and you can purchase and
                                pay the one-time fee by credit card.
                            </li>
                            <li>
                                If you have a login and password supplied by your career counselor/coach or if you previously signed up
                                as an individual, simply{' '}
                                <a href="https://www.lifecareervalues.com/member/login1.cfm">login here</a> now.
                            </li>
                            <li>
                                If you are a career counselor/coach and would like to offer the assessment to your clients, purchase and
                                pay the use fee by credit card.
                            </li>
                        </ul>
                        <li>
                            Third, follow the instructions after you login. You may want to allow 20-30 minutes if you complete
                            everything all at once (but you do not have to - see the <a href="page-faq.cfm">FAQs</a>)
                        </li>
                    </ul>

                    <h4 className='tw-text-[#37B048] tw-text-xl tw-my-5'>What do I get when I take the assessment?</h4>

                    <p className='tw-mt-4'>
                        For each of the three parts, (1) Personal/Whole Life Values, (2) Current/Most Recent Career Values, and (3)
                        Ideal Career Values, you will have a list of ten of the most important values to you in order from highest
                        to lowest. You then follow the instructions on your personalized results to explore the meaning of your
                        values. Finally, review the helpful pages on <a href="results.cfm">Results</a> and{' '}
                        <a href="nextsteps.cfm">Next Steps pages of the website</a>.
                    </p>
                    <center>
                        <p className='tw-mt-4'></p>

                        <button className='btn btn--sky'>Login Now!</button>
                    </center>
                </div>
            </div>
        </div>
    );
};

export default Assessment;
