import React from 'react'
import "./Loader.scss"

import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
    return (
        <div className="spinner">
            <CircularProgress color="success" />
        </div>
    )
}

export default Loader