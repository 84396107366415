import { FormControl, OutlinedInput, TextField } from '@mui/material'
import React from 'react'
import "./Footer.scss"
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <footer className='md:tw-flex tw-block'>
            {/* <div className='footer-left-section md:tw-relative  md:tw-w-[40%] tw-w-full tw-px-7 tw-py-4 tw-bg-[#EBEBEB]'>
                <h2 className='tw-text-[32px]'>Need Career Help?</h2>
                <p className='tw-mw-[500px] tw-text-[#666666] tw-mt-5'>Individuals may learn about the services career counselors offer and how to choose a counselor. Then search for a career counselor who is a member of the National Career Development (NCDA) association.</p>
                <div className='tw-flex tw-justify-center tw-items-center tw-mt-5 md:tw-mt-16'>
                    <button className='btn btn--sky'>CONTACT ME!</button>
                </div>
                <p className='tw-text-[#666666] tw-mt-8 md:tw-absolute tw-bottom-5' >© Copyright 2024 Career Development Alliance. All Rights Reserved.</p>
            </div> */}
            <div className='footer-right-section  tw-w-full   tw-bg-[#37b048] tw-text-white tw-relative tw-pr-6 tw-pb-5 '>
                <div className='tw-grid  md:tw-grid-cols-1 tw-grid-cols-1'>
                    <div className=' tw-p-8'>
                        <p className=' tw-text-[32px] tw-text-center tw-mb-4'>Quick Links</p>
                        <div className='tw-grid md:tw-grid-cols-5 tw-grid-cols-2 tw-gap-4 tw-text-center'>
                            <Link to={`/`} className='tw-cursor-pointer'>HOME</Link>
                            <Link to={`/about`} className='tw-cursor-pointer'>ABOUT ME</Link>
                            <Link to={`/assessment`} className='tw-cursor-pointer'>THE ASSESSMENT</Link>
                            <Link to={`/faq`} className='tw-cursor-pointer'>FAQS</Link>
                            <Link to={`/nextSteps`} className='tw-cursor-pointer'>NEXT STEPS</Link>
                            {/* <p className='tw-cursor-pointer'>BLOGS</p> */}
                            {/* <p className='tw-cursor-pointer'>RESULTS</p> */}
                            {/* <p className='tw-cursor-pointer'>CONTACT US</p> */}
                        </div>
                    </div>
                    {/* <div className=' tw-p-8'>
                        <p className=' tw-text-[32px]'>Connect With Us</p>
                        <form>
                            <div className='tw-max-w-xs'>
                                <FormControl sx={{ width: '100%', marginTop: '10px', border: 'none' }}>
                                    <OutlinedInput
                                        placeholder="Name" className='primary-text-1 tw-font-semibold tw-text-sm '

                                    />
                                </FormControl>
                                <FormControl sx={{ width: '100%', marginTop: '10px', border: 'none' }}>
                                    <OutlinedInput
                                        placeholder="Email Address" className='primary-text-1 tw-font-semibold tw-text-sm '

                                    />
                                </FormControl>
                                <div className='tw-mt-5'>
                                    <TextField
                                        fullWidth
                                        placeholder={`message`}
                                        margin="normal"
                                        variant="outlined"
                                      
                                        multiline
                                        rows={3}

                                    />
                                </div>
                            </div>
                        </form>
                    </div> */}
                    {/* <div className='send-button-container tw-absolute tw-right-3 tw-top-28'>
                        <div className='send-button'>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M24.634 0.366019C24.4626 0.194597 24.2449 0.0767384 24.0077 0.0268013C23.7705 -0.0231359 23.5238 -0.00299652 23.2978 0.0847661L0.7988 8.83485C0.548506 8.93172 0.336038 9.10659 0.192826 9.33358C0.0496144 9.56058 -0.0167357 9.82764 0.00359118 10.0953C0.0239181 10.3629 0.129843 10.6169 0.305695 10.8196C0.481548 11.0224 0.717988 11.1632 0.980042 11.2211L11.4533 13.5474L13.7807 24.0212C13.8389 24.2829 13.9797 24.5189 14.1822 24.6945C14.3848 24.87 14.6384 24.9758 14.9057 24.9963L15.0007 25C15.2526 25 15.4986 24.9238 15.7065 24.7815C15.9144 24.6392 16.0744 24.4373 16.1656 24.2025L24.9152 1.70228C25.003 1.47627 25.0231 1.22961 24.9732 0.992351C24.9233 0.755094 24.8054 0.53748 24.634 0.366019ZM15.3282 19.4599L13.8757 12.8986L16.5156 10.2586C16.7433 10.0229 16.8693 9.7071 16.8664 9.37935C16.8636 9.0516 16.7321 8.73808 16.5004 8.50632C16.2686 8.27456 15.9551 8.1431 15.6274 8.14025C15.2997 8.1374 14.9839 8.26339 14.7482 8.49109L12.1021 11.1249L5.54109 9.67236L21.5554 3.4448L15.3282 19.4599Z" fill="#37B048"></path> </svg>
                        </div>
                    </div> */}
                </div>
                <p className='tw-pb-1 tw-pl-5 tw-text-center'>LifeCareerValues Career Development Alliance LLC Washington D.C. Metro area</p>
                <p className='tw-text-center'>© Copyright 2024 Career Development Alliance. All Rights Reserved.</p>
            </div>
        </footer>
    )
}

export default Footer