import AboutMe from '@Pages/landing-page/components/about-me/AboutMe'
import Features from '@Pages/landing-page/components/features/Features'
import FiveSteps from '@Pages/landing-page/components/five-steps/FiveSteps'
import QuotesSection from '@Pages/landing-page/components/quotes-section/QuotesSection'
import { FormControl, OutlinedInput } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./Login.scss"
import { IAPIResponse, IToken } from '@Src/types/api-response-interface'
import { MODULES_API_MAP, httpService } from '@Src/services/httpService'
import { GLOBAL_API_ROUTES } from '@Src/services/globalApiRoutes'
import { getDataFromLocalStorage, setDataOnLocalStorage } from '@Src/utils/globalUtilities'
import { LOCAL_STORAGE_DATA_KEYS } from '@Constants/localstorageDataModel'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '@Components/loader/Loader'


const Login = () => {
    const [showSignUp, setShowSignUp] = useState(false)
    const [loginData, setLoginData] = useState({ email: '', password: '' })
    const [signupData, setSignupData] = useState({ name: '', email: '', password: '' })
    const [loginErrors, setLoginErrors] = useState<any>({})
    const [signupErrors, setSignupErrors] = useState<any>({})
    const [customErrors, setCustomErrors] = useState<any>()
    const [showLoader, setShowLoader] = useState(false)
    const navigate = useNavigate()

    const validateEmail = (email: any) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (!email) return 'Email is required'
        if (!emailPattern.test(email)) return 'Invalid email address'
        return ''
    }

    const validatePassword = (password: any) => {
        if (!password) return 'Password is required'
        if (password.length < 6) return 'Password must be at least 6 characters'
        return ''
    }



    const handleLoginSubmit = async (e: any) => {
        e.preventDefault()
        const emailError = validateEmail(loginData.email)
        const passwordError = validatePassword(loginData.password)
        if (emailError || passwordError) {
            setLoginErrors({ email: emailError, password: passwordError })
        } else {
            setShowLoader(true)
            setLoginErrors({})
            try {
                const response = await axios.post('https://api-zrvnv6zlpa-uc.a.run.app/login', loginData)
                if (response.data.success) {
                    setDataOnLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY, response.data.data.token)
                    navigate('/dashboard')
                }
                setCustomErrors('')

            } catch (error: any) {
                if (error?.response.data.errors && error?.response.data?.errors[0]?.message) {
                    setCustomErrors(error?.response.data?.errors[0]?.message)
                } else {
                    setCustomErrors('Something went wrong')
                }
            }
            setShowLoader(false)
        }
    }

    const handleSignupSubmit = async (e: any) => {
        e.preventDefault()
        const emailError = validateEmail(signupData.email)
        const passwordError = validatePassword(signupData.password)
        if (emailError || passwordError) {
            setSignupErrors({ email: emailError, password: passwordError })
        } else {
            setShowLoader(true)
            setSignupErrors({})
            try {
                const response = await axios.post('https://api-zrvnv6zlpa-uc.a.run.app/signup', signupData)
                if (response.data.success) {
                    setDataOnLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY, response.data.data.token)
                    navigate('/dashboard')
                }
                setCustomErrors('')

            } catch (error: any) {
                if (error?.response.data.errors && error?.response.data?.errors[0]?.message) {
                    setCustomErrors(error?.response.data?.errors[0]?.message)
                } else {
                    setCustomErrors('Something went wrong')
                }
            }
            setShowLoader(false)

        }
    }

    const handleInputChange = (e: any, formType: any) => {
        const { name, value } = e.target
        if (formType === 'login') {
            setLoginData({ ...loginData, [name]: value })
            if (loginErrors[name]) {
                setLoginErrors({ ...loginErrors, [name]: '' })
            }
        } else {
            setSignupData({ ...signupData, [name]: value })
            if (signupErrors[name]) {
                setSignupErrors({ ...signupErrors, [name]: '' })
            }
        }
    }

    const token = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);
    useEffect(() => {
        if (token) {
            const decodeToken: IToken = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000);
            if (decodeToken.exp < currentTime) {
                navigate('/')
                window.localStorage.clear()
            } else {
                navigate('/dashboard')
            }
        }
    }, [token])

    return (
        <div className='login-container'>
            {showLoader && <Loader />}
            <div className='tw-flex tw-justify-center tw-items-center tw-p-4'>
                {!showSignUp ? (
                    <form onSubmit={handleLoginSubmit} className='login-form'>
                        <p className='tw-text-xl tw-font-bold tw-text-center'>Login</p>
                        <div className='tw-max-w-xs'>
                            <p className='tw-mt-3 tw-font-semibold'>Email:</p>
                            <FormControl sx={{ width: '100%', marginTop: '10px', border: 'none' }}>
                                <OutlinedInput
                                    name="email"
                                    placeholder="Email Address"
                                    className='primary-text-1 tw-font-semibold tw-text-sm'
                                    value={loginData.email}
                                    onChange={(e) => handleInputChange(e, 'login')}
                                />
                                {loginErrors.email && <p className='validation-msg'>{loginErrors.email}</p>}
                            </FormControl>
                            <p className='tw-mt-3 tw-font-semibold'>Password:</p>
                            <FormControl sx={{ width: '100%', marginTop: '10px', border: 'none' }}>
                                <OutlinedInput
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    className='primary-text-1 tw-font-semibold tw-text-sm'
                                    value={loginData.password}
                                    onChange={(e) => handleInputChange(e, 'login')}
                                />
                                {loginErrors.password && <p className='validation-msg'>{loginErrors.password}</p>}
                            </FormControl>
                            {customErrors?.length > 1 && <p className='validation-msg'>{customErrors}</p>}

                            <button type="submit" className='tw-px-7 tw-py-3 tw-mt-5 btn--green'>Login</button>
                            <p className='tw-mt-4'>Don't have an account?<b onClick={() => setShowSignUp(true)} className='tw-cursor-pointer tw-underline'> Create a new account</b></p>
                        </div>
                    </form>
                ) : (
                    <form onSubmit={handleSignupSubmit} className='login-form'>
                        <p className='tw-text-xl tw-font-bold tw-text-center'>Sign up</p>
                        <div className='tw-max-w-xs'>
                            {/* <p className='tw-mt-3 tw-font-semibold'>Name:</p>
                            <FormControl sx={{ width: '100%', marginTop: '10px', border: 'none' }}>
                                <OutlinedInput
                                    name="name"
                                    placeholder="Name"
                                    className='primary-text-1 tw-font-semibold tw-text-sm'
                                    value={signupData.name}
                                    onChange={(e) => handleInputChange(e, 'signup')}
                                />
                                {signupErrors.name && <p className='validation-msg'>{signupErrors.name}</p>}
                            </FormControl> */}
                            <p className='tw-mt-3 tw-font-semibold'>Email:</p>
                            <FormControl sx={{ width: '100%', marginTop: '10px', border: 'none' }}>
                                <OutlinedInput
                                    name="email"
                                    placeholder="Email Address"
                                    className='primary-text-1 tw-font-semibold tw-text-sm'
                                    value={signupData.email}
                                    onChange={(e) => handleInputChange(e, 'signup')}
                                />
                                {signupErrors.email && <p className='validation-msg'>{signupErrors.email}</p>}
                            </FormControl>
                            <p className='tw-mt-3 tw-font-semibold'>Password:</p>
                            <FormControl sx={{ width: '100%', marginTop: '10px', border: 'none' }}>
                                <OutlinedInput
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    className='primary-text-1 tw-font-semibold tw-text-sm'
                                    value={signupData.password}
                                    onChange={(e) => handleInputChange(e, 'signup')}
                                />
                                {signupErrors.password && <p className='validation-msg'>{signupErrors.password}</p>}
                            </FormControl>
                            {customErrors?.length > 1 && <p className='validation-msg'>{customErrors}</p>}
                            <button type="submit" className='tw-px-7 tw-py-3 tw-mt-5 btn--sky'>Sign up</button>
                            <p className='tw-mt-4'>Already have an account?<b onClick={() => setShowSignUp(false)} className='tw-cursor-pointer tw-underline'> Sign in</b></p>
                        </div>
                    </form>
                )}
            </div>
            {/* Features Section */}
            {/* <Features /> */}
            {/* Quotes */}
            {/* <QuotesSection /> */}
            {/* About Me */}
            {/* <AboutMe /> */}
            {/* Steps to success */}
            {/* <FiveSteps /> */}
        </div>
    )
}

export default Login
