import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
const Faq = () => {
    const faqData = [
        { question: 'How do I take the assessment?', answer: 'Simply Purchase and Login. If you have a counselor or coach, follow your counselor s instructions.' },
        { question: 'I received a login and password from my career coach where do I start?', answer: 'Login and follow the instructions to take the assessment. Your coach will be notified when you have completed the assessment. While your coach can access your results, it is also good to save or print your results for yourself.' },
        { question: 'What will I do when taking the assessment?', answer: 'The assessment is like a card sort, in that you are given statements (values) to "sort" into different "piles" depending on how important it is to you. It consists of determining your values in three parts: (1) Personal/Whole Life Values, (2) Current/Most Recent Career Values, and (3) Ideal Career Values. For example, you may see the words "Avoiding Conflict" and "Working Internationally" and you must decide which of these values you hope to have in your ideal career. In this way, you are creating lists of what is important to you, which you can compare to understand, define and think about in terms of your life and career.' },
        { question: 'How long will it take to complete the assessment?', answer: 'Individuals take their own time in selecting their answers. Because values are so important, feel free to take as much time as you need. Sometimes a quick "gut" instinct provides a fine answer! This means it may only take 20 minutes or less for you to complete the assessment. However, do not worry if it takes longer than 30 minutes and, yes, you can always stop and come back.' },
        { question: 'Do I need to take all three parts of the assessment at once?', answer: 'You can use the logout button to stop and you may return later by logging back in (just be sure to remember your username and password). Your results for each part you completed will be saved. HOWEVER: if you leave while in the middle of taking a part of the assessment, your results for that part will not be retained.' },
        {
            question: 'I want to change some of my answers -- can I do that?', nswer: `To change your answers while still taking a segment: click your browser's "Back" button and make your changes. When you click "Next" on the assessment panel, your new answers will be saved and you can finish the segment.To change your answers after you have completed a segment: Once you complete a segment, it is not possible to go back and change selected answers. However, you may revisit that segment one time and your new answers will be saved - look for a "Revisit?" button below that segment on your results page. \n This button allows you to revisit each segment, starting from scratch - but only one time.`
        },
        { question: 'I took this a while back. Can I take it again now?', answer: 'To re-take the entire assessment, please purchase and login with a new User Name and password. Or you may ask your counselor/coach for a new user id. Values usually do not change in a short period of time. However your frame of mind when taking an assessment may be different a second time. Your answers may or may not be different, and it is always good to put extra thought into values.' },
        { question: 'It looks like I have to complete three steps for each of the three sections - why can t everything be one short list?', answer: 'Thinking about and comparing your responses to all three parts of all three sections can help you see whether or not there is a conflict in your life and career that may cause you to feel a disconnect. You can then use this information to help you make changes that can lead to a more satisfying career and life.' },
        { question: 'Will my results tell me what career I should or should not choose?', answer: 'Values can help you think about careers choices, but cannot directly tell you what career you should choose. (No assessment can tell you this!) View the Results and Next Steps section for more details and remember a career counselor/coach may be able to best help you.' },
        { question: 'Will others be able to see my results (or can I see theirs)?', answer: 'The assessment is secure so that only you (and, if you have one, your counselor/coach) can see your results.' },
        { question: 'Can I take this on my phone or using an app or using a set of real cards?', answer: 'At this time, the assessment is only available online.' },
        { question: 'What do my results mean to me and what do I do now?', answer: 'Once you have completed the assessment, you will receive a detailed, personal report. For each of the the three parts you completed, (1) Personal/Whole Life Values, (2) Current/Most Recent Career Values, and (3) Ideal Career Values, you will have a list of ten of the most important values to you in order from highest to lowest. You then follow the instructions in the Results report to explore the meaning of these three lists. You may also want to view the Next Steps page for more details.' },
        { question: 'I\'m having trouble using the assessment. What do I do?', answer: 'First, try logging out and back in or reviewing the email from your coach. Next, try contacting your coach to ask your question. Finally, for credit card help or changing your email address you may email us at our Support Desk .' },
    ]

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    return (
        <div>
            <div className='faq-list md:tw-px-[10rem] md:tw-py-8 tw-p-4 '>
                <h1 className='tw-text-xl tw-mb-5'>This page answers some of the most frequently asked questions by <b>individuals.</b> about signing up, logging in and using LifeCareerValues.com. We also have FAQs for Coaches / Counselors.</h1>
                {faqData.map((item, index) => {
                    return (
                        <Accordion key={index} expanded={expanded === item.question} onChange={handleChange(item.question)}>
                            <AccordionSummary
                                expandIcon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill={expanded === item.question ? "white" : ''}>
                                        <path d="M13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315ZM8 15C4.1402 15 1.00003 11.8598 1.00003 8C1.00003 4.1402 4.1402 1.00003 8 1.00003C11.8598 1.00003 15 4.1402 15 8C15 11.8598 11.8598 15 8 15ZM11.5198 6.37585C11.6136 6.46962 11.6662 6.59679 11.6662 6.7294C11.6662 6.862 11.6136 6.98917 11.5198 7.08294L8.35353 10.2492C8.25976 10.3429 8.1326 10.3956 8 10.3956C7.8674 10.3956 7.74024 10.3429 7.64647 10.2492L4.48023 7.08294C4.3874 6.98898 4.33553 6.86211 4.33593 6.73002C4.33633 6.59794 4.38898 6.47139 4.48237 6.37799C4.57577 6.28459 4.70233 6.23195 4.83441 6.23155C4.96649 6.23115 5.09336 6.28302 5.18732 6.37585L8 9.18853L10.8127 6.37585C10.9065 6.28209 11.0337 6.22941 11.1663 6.22941C11.2989 6.22941 11.426 6.28209 11.5198 6.37585Z" fill={expanded === item.question ? "white" : '#39424E'} />
                                    </svg>
                                }
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className='tw-bg-slate-700'
                                sx={{ borderLeft: '4px solid #37B048', backgroundColor: expanded === item.question ? '#37B048' : '', color: expanded === item.question ? '#ffffff' : '', marginTop: '10px' }}
                            >
                                <Typography className={`accordion-title `} >
                                    {item.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='accordion-content tw-text-sm tw-pb-2' dangerouslySetInnerHTML={{ __html: item.answer || '' }}>

                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        </div>
    )
}

export default Faq