import React, { Suspense, useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import AuthGuardRoute from './guards/AuthGuardRoutes';
import NavBar from '@Components/navbar/NavBar';
import { FormControl, OutlinedInput } from '@mui/material';
import Footer from '@Components/footer/Footer';
import { AppRouteURLs } from '@Constants/app-route-urls';
import HomeVideo from '@Pages/home-video/HomeVideo';
import Login from '@Pages/Login/Login';
import Faq from '@Pages/faq/Faq';
import { LOCAL_STORAGE_DATA_KEYS } from '@Constants/localstorageDataModel';
import { jwtDecode } from 'jwt-decode';
import { getDataFromLocalStorage } from './utils/globalUtilities';
import { IToken } from './types/api-response-interface';
import Assessment from '@Pages/assessment/Assessment';
import NextSteps from '@Pages/next-steps/NextSteps';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Dashboard from '@Pages/dashboard/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ScrollToTop } from '@Components/ScrollToTop/ScrollToTop';


// Lazy Loading
const Layout = React.lazy(() => import('@Components/layout/Layout'));

const LandingPage = React.lazy(() => import('@Pages/landing-page/LandingPage'))

function App() {
	return (
		<div className="App">
			<div className="">
				<NavBar />
			</div>
			<div className='tw-mt-24'>
				<Suspense fallback={<div></div>}>
					<ScrollToTop />
					<Routes>
						<Route path='/*' element={
							<AuthGuardRoute>
								<Layout />
							</AuthGuardRoute>
						}></Route>
						<Route path={'/'} element={<LandingPage />} />
						<Route path={AppRouteURLs.ABOUT} element={<LandingPage />} />
						<Route path={AppRouteURLs.HOME_VIDEO} element={<HomeVideo />} />
						<Route path={AppRouteURLs.LOGIN} element={<Login />} />
						<Route path={AppRouteURLs.FAQ} element={<Faq />} />
						<Route path={AppRouteURLs.ASSESSMENT} element={<Assessment />} />
						<Route path={AppRouteURLs.NEXT_STEPS} element={<NextSteps />} />
						<Route path={AppRouteURLs.DASHBOARD} element={<Dashboard />} />
					</Routes>
				</Suspense>
			</div>
			<div className='tw-mt-16'>
				<Footer />
			</div>
			<div>
				<ToastContainer
					position="top-center"
					autoClose={2500}
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					limit={2}
					toastClassName={'theme-toast'}
				/>
			</div>
		</div >
	);
}

export default App;

